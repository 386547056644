import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

interface HomeSliderImageProps {
  index: number,
  readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'altText' | 'description' | 'gatsbyImage' | 'width' | 'title' | 'srcSet' | 'height' | 'filename' | 'sourceUrl' | 'guid'>>
}
const HomeSliderImage = ({index, image}: HomeSliderImageProps) => {
  if (! image?.gatsbyImage ) return null
  return (
    <figure className="h-[400px] w-full">
      <GatsbyImage
        alt={image?.altText || image?.description || ``}
        image={image?.gatsbyImage!}
        className="object-cover object-top w-full h-full"
        loading={index > 1 ? "lazy" : "eager"}
      />
    </figure>
  )
}

export default HomeSliderImage