import React from "react"
import { graphql } from "gatsby"

// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/UI/Layout"
import ProjectSlider from "../components/Content/ProjectSlider"
import Seo from "../components/UI/Seo"

type PageTemplateProps = {
  data: GatsbyTypes.HomePageQuery
}

const PageTemplate = ({ data }: PageTemplateProps) => {
  const { page } = data
  return (
    <Layout pageTitle={page?.title!} isHomePage={true}>
      <Seo title={page?.title} meta={[
        { name: `description`, content: page?.seo?.metaDesc || `` },
        { name: `keywords`, content: page?.seo?.metaKeywords || `` },
        { property: `og:title`, content: page?.seo?.opengraphTitle || page?.title! },
        { property: `og:description`, content: page?.seo?.opengraphDescription! },
        { property: `og:type`, content: `website` },
        { property: `og:author`, content: page?.seo?.opengraphAuthor || `Neha Kale` },
        { property: `og:image`, content: page?.seo?.opengraphImage?.sourceUrl || `` },
        { property: `og:image:alt`, content: page?.seo?.opengraphImage?.altText || `` },
        { property: `og:url`, content: page?.seo?.canonical || `` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: page?.seo?.twitterTitle || page?.title! },
        { name: `twitter:description`, content: page?.seo?.twitterDescription! },
        { name: `twitter:image`, content: page?.seo?.twitterImage?.sourceUrl! },
      ]} />
      <article
        className=""
        itemScope
        itemType="http://schema.org/Article"
      >
        <ProjectSlider projects={page?.acf?.projects!} slideTime={5000} />
      </article>
    </Layout>
  )
}

export default PageTemplate

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    page: wpPage(id: {eq: $id}) {
      title
      uri
      acf: homePage {
        fieldGroupName
        projects {
          title
          image {
            altText
            description
            gatsbyImage(height: 450, width: 798, placeholder: BLURRED, formats: [AUTO, WEBP])
            width
            title
            srcSet
            height
            filename
            sourceUrl
            guid
          }
          description
          projectLinkCopy {
            target
            title
            url
          }
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaKeywords
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
      }
    }
  }
`