import React, { useEffect, useState } from "react";

interface SliderProps {
  totalProjects: number,
  slideTime: number,
}

export default function useSlider({ totalProjects, slideTime }: SliderProps): [number, React.Dispatch<React.SetStateAction<number>>] {

  const [activeSlide, setActiveSlide] = useState(1)

  useEffect(() => {
    if (totalProjects && slideTime) {
      const next = (activeSlide + 1 > totalProjects ? 1 : activeSlide + 1);
      const id = setTimeout(() => setActiveSlide(next), slideTime);
      return () => clearTimeout(id);
    }
  }, [activeSlide]);

  return [activeSlide, setActiveSlide]
}