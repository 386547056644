import React from "react"
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/outline"
import HomeSliderImage from "./HomeSliderImage"
import useSlider from "../../hooks/useSlider"

type ProjectSliderProps = {
  slideTime: number,
  readonly projects: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<(
    Pick<GatsbyTypes.WpPage_Homepage_projects, 'title' | 'description'>
    & { readonly image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'altText' | 'description' | 'gatsbyImage' | 'width' | 'title' | 'srcSet' | 'height' | 'filename' | 'sourceUrl' | 'guid'>>, readonly projectLinkCopy: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpAcfLink, 'target' | 'title' | 'url'>> }
  )>>>
}

const ProjectSlider = ({ projects, slideTime }: ProjectSliderProps) => {

  const totalProjects: number = projects?.length || 0

  const [activeSlide, setActiveSlide] = useSlider({ totalProjects, slideTime });

  return (
    <div id="slide-wrapper" className="mb-48 ">
      <div className="slider relative h-[400px] overflow-hidden z-10">

        {projects?.map((project, index) => {
          const { title, description, image, projectLinkCopy } = project!

          return (
            <article key={`slide-${index + 1}`}
              id={`slide-${index + 1}`}
              className={`cursor-pointer absolute -z-0
                h-full w-full
                transition-opacity duration-500
                ${activeSlide === index + 1 ? `opacity-100 right-[unset]` : `opacity-0 right-[100%]`}`}
              >
              <a href={projectLinkCopy?.url} title={projectLinkCopy?.title}>
                <div className="absolute z-10 top-0 left-0
                  h-full w-full p-8
                  transition-opacity duration-500 opacity-0 hover:opacity-100">
                  <div className="bg-white h-full w-full p-5">
                    <hr className="block mt-5 mb-10 w-52 border-cyan-800" />
                    <h2 className="font-bold not-italic">{title}</h2>
                    <p>{description}</p>
                  </div>
                </div>
                <HomeSliderImage index={index} image={image} />
              </a>
            </article>
          )
        })}

      </div>
      <div id="slider-navigation" className="flex justify-between">
        <div id="slide-left" className="pt-2 h-10 w-10 cursor-pointer"
          onClick={
            () => setActiveSlide(activeSlide - 1 < 1 ? totalProjects : activeSlide - 1)
          }>
          <ArrowNarrowLeftIcon className="h-5 w-5" />
        </div>
        <div id="slide-right" className="pt-2 h-10 w-10 cursor-pointer text-right"
          onClick={
            () => setActiveSlide(activeSlide + 1 > totalProjects ? 1 : activeSlide + 1)
          }>
          <ArrowNarrowRightIcon className="h-5 w-5" />
        </div>
      </div>
    </div>
  )
}



export default ProjectSlider